const permissionsMixin = {
    computed: {
        accessAttendances () {
            return this.$user?.staff ? this.$user.staff.access_attendances : true
        },
        accessAvailability () {
            return this.$user?.staff ? this.$user.staff.access_availability : true
        },
        accessBabies () {
            return this.$user?.staff ? this.$user.staff.access_babies : true
        },
        accessBilling () {
            return this.$user?.staff ? this.$user.staff.access_billing : true
        },
        accessCommunications () {
            return this.$user?.staff ? this.$user.staff.access_communications : true
        },
        accessMedical () {
            return this.$user?.staff ? this.$user.staff.access_medical : true
        },
        accessRegistrations () {
            return this.$user?.staff ? this.$user.staff.access_registrations : true
        },
        accessReports () {
            return this.$user?.staff ? this.$user.staff.access_reports : true
        },
        accessSettings () {
            return this.$user?.staff ? this.$user.staff.access_settings : true
        },
        accessStaff () {
            return this.$user?.staff ? this.$user.staff.access_staff : true
        }
    }
}

export default permissionsMixin
